<template>
    <div class="h-screen flex w-full bg-img" id="page-login">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center flex justify-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row m-0">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white">
                            <div class="px-8 py-20">
                                <div class="vx-card__title text-center mb-8">
                                    <h3 class="mb-4">Radix Watchtower</h3>
                                    <p>Welcome back, please login to your account.</p>
                                </div>
                                <vs-button class="flex mx-auto" type="border" @click="loginWithGoogle()">
                                    <div class="flex justify-center">
                                        <svg class="google-g mr-2" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/></svg>
                                        <span>Sign in with Google</span>
                                    </div>
                                </vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                email: '',
                password: '',
                checkbox_remember_me: false
            }
        },
        computed: {
            validateForm() {
                return !this.errors.any() && this.email != '' && this.password != '';
            },
        },
        methods: {
            // Google login
            loginWithGoogle() {
				if(this.$gAuth.isInit) {
					this.$gAuth.signIn()
					.then(GoogleUser => {
						let auth = GoogleUser.getAuthResponse();

						// Give AuthResponse to backend
						this.$axios.post(`${this.$API}/oauth/token`, {
							grant_type: 'social', // static 'social' value
							client_id: 1, // client id
							client_secret: process.env.VUE_APP_CLIENT_SECRET, // client secret
							provider: 'google', // name of provider (e.g., 'facebook', 'google' etc.)
							access_token: auth['access_token'], // access token issued by specified provider
							expires_in: auth['expires_in'], // access token expiry
						})
						.then((response) => {
							if (response.data) {
								// Store access token in localstorage
								localStorage.setItem('accessToken', response.data['access_token']);
								// Set auth headers
								this.$axiosSecure.defaults.headers.common['Authorization'] = 'Bearer ' + response.data['access_token'];
								// Get user data
								this.$axiosSecure.get(`/user`)
								.then((response) => {
									if (response.data.email) {
										// Set user details
										this.$store.dispatch('auth/updateAuthenticatedUser', response.data);
										setTimeout(() => {
											// Redirect to dashboard
											this.$router.replace('/');
										}, 100);
									}
									else {
										// Invalid notification
										this.$vs.notify({
											title: 'Login Attempt',
											text: 'Invalid credentials',
											iconPack: 'feather',
											icon: 'icon-alert-circle',
											color: 'danger'
										});
										// Clear user details
										this.$store.dispatch('auth/clearAuthenticatedUser');
										// Redirect to login
										this.$router.replace('/pages/login');
									}
								})
								.catch(error  => {
									// Invalid notification
									this.$vs.notify({
										title: 'Login Attempt',
										text: 'Invalid credentials',
										iconPack: 'feather',
										icon: 'icon-alert-circle',
										color: 'danger'
									});
									// If user is not logged in
									if(error.response.status == 401) {
										// Clear user details
										this.$store.dispatch('auth/clearAuthenticatedUser');
										// Redirect to login
										this.$router.replace('/pages/login');
									}
								})
							}
							else {
								// Invalid notification
								this.$vs.notify({
									title: 'Login Attempt',
									text: 'Invalid credentials',
									iconPack: 'feather',
									icon: 'icon-alert-circle',
									color: 'danger'
								});
								// Clear user details
								this.$store.dispatch('auth/clearAuthenticatedUser');
								// Redirect to login
								this.$router.replace('/pages/login');
							}
						})
						.catch(error  => {
							// Error notification
							this.$vs.notify({
								color: 'danger',
								title: 'Something went wrong',
								text: 'Please contact the server admin'
							});

							console.log(error);
							
							// Clear user details
							this.$store.dispatch('auth/clearAuthenticatedUser');
								// Redirect to login
								this.$router.replace('/pages/login');
						})
					})
					.catch(error  => {
						if(error['error'] != 'popup_closed_by_user') {
							// Error notification
							this.$vs.notify({
								color: 'danger',
								title: 'Something went wrong',
								text: 'Please contact the server admin'
							});

							console.log(error);
						}
						
						// Clear user details
						this.$store.dispatch('auth/clearAuthenticatedUser');
						// Redirect to login
						this.$router.replace('/pages/login');
					})
				}
            },
        },
        mounted() {

        }
    }
</script>

<style lang="scss">
#page-login {
    .google-g {
        height: 20px;
        width: 20px;
    }
}
</style>
